import React, { useState, useRef } from 'react';

import { Helmet } from 'react-helmet'
import emailjs from '@emailjs/browser';

import './contact-us.css'

const Contact = (props) => {
    // Form state
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        country: '',
        transactionType: '',
        inquiry: ''
    });

    // State to manage the visibility of the success message
    const [successMessage, setSuccessMessage] = useState('');
    const form = useRef();

    const countries = [
        'Afghanistan',
'Albania',
'Algeria',
'Andorra',
'Angola',
'Antigua & Deps',
'Argentina',
'Armenia',
'Australia',
'Austria',
'Azerbaijan',
'Bahamas',
'Bahrain',
'Bangladesh',
'Barbados',
'Belarus',
'Belgium',
'Belize',
'Benin',
'Bhutan',
'Bolivia',
'Bosnia Herzegovina',
'Botswana',
'Brazil',
'Brunei',
'Bulgaria',
'Burkina',
'Burundi',
'Cambodia',
'Cameroon',
'Canada',
'Cape Verde',
'Central African Rep',
'Chad',
'Chile',
'China',
'Colombia',
'Comoros',
'Congo',
'Congo {Democratic Rep}',
'Costa Rica',
'Croatia',
'Cuba',
'Cyprus',
'Czech Republic',
'Denmark',
'Djibouti',
'Dominica',
'Dominican Republic',
'East Timor',
'Ecuador',
'Egypt',
'El Salvador',
'Equatorial Guinea',
'Eritrea',
'Estonia',
'Ethiopia',
'Fiji',
'Finland',
'France',
'Gabon',
'Gambia',
'Georgia',
'Germany',
'Ghana',
'Greece',
'Grenada',
'Guatemala',
'Guinea',
'Guinea-Bissau',
'Guyana',
'Haiti',
'Honduras',
'Hungary',
'Iceland',
'India',
'Indonesia',
'Iran',
'Iraq',
'Ireland {Republic}',
'Israel',
'Italy',
'Ivory Coast',
'Jamaica',
'Japan',
'Jordan',
'Kazakhstan',
'Kenya',
'Kiribati',
'Korea North',
'Korea South',
'Kosovo',
'Kuwait',
'Kyrgyzstan',
'Laos',
'Latvia',
'Lebanon',
'Lesotho',
'Liberia',
'Libya',
'Liechtenstein',
'Lithuania',
'Luxembourg',
'Macedonia',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Malta',
'Marshall Islands',
'Mauritania',
'Mauritius',
'Mexico',
'Micronesia',
'Moldova',
'Monaco',
'Mongolia',
'Montenegro',
'Morocco',
'Mozambique',
'Myanmar, {Burma}',
'Namibia',
'Nauru',
'Nepal',
'Netherlands',
'New Zealand',
'Nicaragua',
'Niger',
'Nigeria',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Panama',
'Papua New Guinea',
'Paraguay',
'Peru',
'Philippines',
'Poland',
'Portugal',
'Qatar',
'Romania',
'Russian Federation',
'Rwanda',
'St Kitts & Nevis',
'St Lucia',
'Saint Vincent & the Grenadines',
'Samoa',
'San Marino',
'Sao Tome & Principe',
'Saudi Arabia',
'Senegal',
'Serbia',
'Seychelles',
'Sierra Leone',
'Singapore',
'Slovakia',
'Slovenia',
'Solomon Islands',
'Somalia',
'South Africa',
'South Sudan',
'Spain',
'Sri Lanka',
'Sudan',
'Suriname',
'Swaziland',
'Sweden',
'Switzerland',
'Syria',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Togo',
'Tonga',
'Trinidad & Tobago',
'Tunisia',
'Turkey',
'Turkmenistan',
'Tuvalu',
'Uganda',
'Ukraine',
'United Arab Emirates',
'United Kingdom',
'United States',
'Uruguay',
'Uzbekistan',
'Vanuatu',
'Vatican City',
'Venezuela',
'Vietnam',
'Yemen',
'Zambia',
'Zimbabwe',
    ];

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
     
        emailjs.sendForm('service_dddmzym', 'template_6rt6ijc', form.current, '4Ee4sI4W71avph8pk')
          .then((result) => {
            console.log('Email sent', result);
            // Reset form and show a success message
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                contactNumber: '',
                country: '',
                transactionType: '',
                inquiry: ''
            });
            setSuccessMessage('Your message has been sent successfully.');
          }, (error) => {
                setSuccessMessage('Failed to send message. Please try again later.');
                console.error('Server error', error);
          });
      };

    // Handle form reset
    const handleReset = () => {
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            country: '',
            transactionType: '',
            inquiry: ''
        });
    };

    return (
        <div className="container">
            <Helmet>
                <title>Contact Us</title>
                <meta property="og:title" content="Contact Us" />
            </Helmet>
            <span className="home-logo2">
              <span className="home-text84">BUSINESS</span>
              <span className="home-text85">HAAT</span>
            </span>
            <form ref={form} onSubmit={sendEmail} onReset={handleReset} className="contact-form">
                {successMessage && <div className="success-message">{successMessage}</div>}
                <div className='sameLine'>
                    <input type="text" name="firstName" placeholder="First Name" style={{marginRight: "5px"}} value={formData.firstName} onChange={handleChange} required />
                    <input type="text" name="lastName" placeholder="Last Name" style={{marginLeft: "5px"}} value={formData.lastName} onChange={handleChange} required />
                </div>
                <input type="email" name="email" placeholder="Email ID" value={formData.email} onChange={handleChange} required />
                <input type="tel" name="contactNumber" placeholder="Contact Number" value={formData.contactNumber} onChange={handleChange} required />
                <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Your Country</option>
                    {countries.map(country => (
                        <option key={country} value={country}>{country}</option>
                    ))}
                </select>
                <select name="transactionType" value={formData.transactionType} onChange={handleChange} required>
                    <option value="" disabled>Select Option</option>
                    <option value="buy">Buy</option>
                    <option value="sell">Sell</option>
                </select>
                <textarea name="inquiry" placeholder="What are you looking for?" value={formData.inquiry} onChange={handleChange} required />
                {/* Implement CAPTCHA here */}

                <div className="sameLine">
                    <button type="submit" style={{ backgroundColor: '#fb5151', color: 'white', width: '100px' }}>Submit</button>
                    <button type="reset" style={{ backgroundColor: '#000fff', color: 'white', width: '100px' }}>Clear</button>
                </div>
            </form>
        </div>
    );
}

export default Contact
