// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question1-container {
  gap: var(--dl-space-space-unit);
  flex: 1 1;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.question1-text {
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/question1.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,SAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".question1-container {\n  gap: var(--dl-space-space-unit);\n  flex: 1;\n  width: auto;\n  height: auto;\n  display: flex;\n  position: relative;\n  align-items: flex-start;\n  flex-direction: column;\n}\n.question1-text {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
