// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text {
  font-weight: 600;
  line-height: 28px;
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/feature-card.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".feature-card-container {\n  gap: var(--dl-space-space-unit);\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.feature-card-text {\n  font-weight: 600;\n  line-height: 28px;\n}\n@media(max-width: 767px) {\n  .feature-card-feature-card {\n    flex-direction: row;\n  }\n  .feature-card-container {\n    flex-direction: column;\n  }\n}\n@media(max-width: 479px) {\n  .feature-card-container {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
